import React, {Component} from "react";
import PortfolioDate from "../../atoms/PortfolioDate/PortfolioDate";
import PortfolioBlock from "../../atoms/PortfolioBlock/PortfolioBlock";

import './PortfolioItem.scss';

class PortfolioItem extends Component {
    render() {
        return (
            <>
                <PortfolioDate
                    dateStart={this.props.dateStart}
                    dateEnd={this.props.dateEnd}
                />

                <PortfolioBlock
                    nid={this.props.nid}
                    title={this.props.title}
                    subtitle={this.props.subtitle}
                    description={this.props.content}
                    link={this.props.link}
                    tags={this.props.tags}
                    image={this.props.image}
                />
            </>
        )
    }
}

export default PortfolioItem;
