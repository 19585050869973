import React, {Component} from "react";
import PortfolioItem from "../../molecules/PortfolioItem/PortfolioItem";
import './PortfolioList.scss';
import {currentLanguageByPath} from "../../../helpers/MainHelper";

class PortfolioList extends Component {
    getClass(enddate) {
        let className = 'timeline-element';

        if (enddate === '2050-12-31') {
            className += ' now';
        }
        return className;
    };

    render() {
        let pf_items = this.props.data.drupal.entityQuery.items;
        const cards = pf_items.map((element) => {
            let pf_item = element.nl;
            if (currentLanguageByPath() === 'en') {
                pf_item = element.en;
            }

            if (pf_item === null) {
                return '';
            }

            let image = null;
            if (pf_item.fieldMediaImage.length > 0) {
                image = pf_item.fieldMediaImage[0].gatsbyImageFile;
            }

            return (
                <li className={this.getClass(pf_item.fieldTimeline.endValue)} key={pf_item.nid}>
                    <PortfolioItem
                        nid={pf_item.nid}
                        title={pf_item.title}
                        subtitle={pf_item.fieldSubtitle}
                        dateStart={pf_item.fieldTimeline.value}
                        dateEnd={pf_item.fieldTimeline.endValue}
                        content={pf_item.summary ? pf_item.summary : ''}
                        link={pf_item.path.alias}
                        tags={pf_item.fieldTags}
                        image={image}
                    />
                </li>
            )
        });

        return (
            <div className="timeline">
                <ul>
                    {cards}
                </ul>
            </div>
                );
    };
}

export default PortfolioList;
