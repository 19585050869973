import React from "react";
import ProjectText from "../PortfolioComponents/ProjectText";
import ProjectImage from "../PortfolioImage/ProjectImage";

const PortfolioBlock = (props) => {
    return (
        <div className={'project'}>
            <ProjectText
                nid={props.nid}
                link={props.link}
                title={props.title}
                subtitle={props.subtitle}
                description={props.description}
                tags={props.tags}
            />

            <ProjectImage
                nid={props.nid}
                title={props.title}
                image={props.image}
                link={props.link}
            />
        </div>
    );
};

export default PortfolioBlock;
