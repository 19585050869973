import React, {Component} from "react";
import Button from "../../atoms/Buttons/Button";
import './TagList.scss';

class TagList extends Component {
    render() {
        const tags = this.props.tags.map((tag) => {
            return (
            <li key={tag.id}>
                <Button
                    class={'read-more button-orange'}
                    link={'#'}
                    label={tag.label}
                    rel={'no-follow'}
                />
            </li>
            )
        });

        return (
            <div className="tags">
                <span className="title">Tags</span>
                <ul className="tag-list">
                    {tags}
                </ul>
            </div>
        );
    }
}

export default TagList;
