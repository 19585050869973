import React, {Component} from "react";
import Img from 'gatsby-image';
import './ProjectImage.scss';
import {Link} from "gatsby";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {currentLanguageByPath} from "../../../helpers/MainHelper";

class ProjectImage extends Component {
    render() {
        let pathPrefix = currentLanguageByPath();
        if (pathPrefix === 'nl') {
            pathPrefix = '';
        } else {
            pathPrefix = '/' + pathPrefix;
        }
        const pathAlias = pathPrefix + this.props.link.toLowerCase();

        if (this.props.image !== null) {
            return (
                <div className={'project-image'}>
                    <Link to={pathAlias}
                      onClick={e => {
                          // Lets track that custom click
                          trackCustomEvent({
                              // string - required - The object that was interacted with (e.g.video)
                              category: "Portfolio Image Link",
                              // string - required - Type of interaction (e.g. 'play')
                              action: "click",
                              // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                              label: this.props.title,
                              // number - optional - Numeric value associated with the event. (e.g. A product ID)
                              value: this.props.nid
                          })
                          return true;
                      }}>
                        <Img fluid={this.props.image.childImageSharp.fluid} alt={this.props.title} />
                    </Link>
                </div>
            )
        }
        else {
            return (
                <></>
            )
        }
    }
}

export default ProjectImage;
