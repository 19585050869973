import React, {Component} from "react";
import {graphql, StaticQuery} from "gatsby";
import PortfolioList from "./PortfolioList";

class PortfolioListWithData extends Component {
    render() {
        return (
            <StaticQuery query={graphql`
            {
                drupal {
                    entityQuery(
                        entityType: NODE
                        filter: {
                            conditions: [
                                {operator: EQUAL, field: "status", value: "1"}
                                {operator: EQUAL, field: "type", value: "portfolio"}
                            ]
                        },
                        limit: 1000,
                        sort: {field: "field_timeline.end_value", direction: DESC}
                    ) {
                        items {
                            ... on Drupal_EntityTranslatable {
                                nl: translation(langcode: NL) {
                                    ... on Drupal_NodePortfolio {
                                        ... PortfolioFields
                                    }
                                }
                                en: translation(langcode: EN) {
                                    ... on Drupal_NodePortfolio {
                                        ... PortfolioFields
                                    }
                                }
                            }
                        }
                    }
                }
            }
            `}
                         render={data => (
                             <PortfolioList data={data} />
                         )}
            />
        )
    }
}

export default PortfolioListWithData;

export const PortfolioFields = graphql`
fragment PortfolioFields on Drupal_NodePortfolio {
    nid
    langcode
    title
    fieldSubtitle
    body 
    summary: body(summary:true)
    fieldTimeline {
        value
        endValue
    }
    fieldHighlights 
    fieldTags {
        id
        label
    }
    path {
        alias
    }
    fieldMediaImage {
        ... on Drupal_MediaImage {
            ...PortfolioListMediaImage
        }
    }
}
`;


export const PortfolioListMediaImage = graphql`
  fragment PortfolioListMediaImage on Drupal_MediaImage {
    fieldMediaImage {
      alt
      entity {
        uri
      }
    }
    gatsbyImageFile {
      childImageSharp {
        fluid {
          originalName
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
