import React from "react";
import {FormatDate} from '../../../helpers/MainHelper';

import './PortfolioDate.scss';

const PortfolioDate = (props) => {
    return (
        <div className="date">
            <time className="date-text">{FormatDate(props.dateStart)}<br className="rbr"/> - <br className="rbr"/>{props.dateEnd !== '2050-12-31' ? FormatDate(props.dateEnd) : 'heden'}</time>
        </div>
    );
};

export default PortfolioDate;
