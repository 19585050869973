import React from "react";
import './PortfolioText.scss';
import Button from "../Buttons/Button";
import TagList from "../../molecules/TagList/TagList";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import {currentLanguageByPath, getTranslations} from "../../../helpers/MainHelper";

const ProjectText = (props) => {
    const i18next = getTranslations(currentLanguageByPath());
    let pathPrefix = currentLanguageByPath();
    if (pathPrefix === 'nl') {
        pathPrefix = '';
    } else {
        pathPrefix = '/' + pathPrefix;
    }
    const pathAlias = pathPrefix + props.link.toLowerCase();

    if (pathAlias.startsWith('/nl')) {
        pathAlias.substr(2);
    }

    return (
        <div className={'project-text'}>
            <span className={'title'}>{props.title}</span>
            <span className={'subtitle'}>{props.subtitle}</span>
            <div className={'project-description'}>
                {props.description}
            </div>
            <Button
                onClick={e => {
                    // Lets track that custom click
                    trackCustomEvent({
                        // string - required - The object that was interacted with (e.g.video)
                        category: "Portfolio Text Link",
                        // string - required - Type of interaction (e.g. 'play')
                        action: "click",
                        // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                        label: props.title,
                        // number - optional - Numeric value associated with the event. (e.g. A product ID)
                        value: props.nid
                    })
                    return true;
                }}
                link={pathAlias}
                label={i18next.t('more_information')}
                class={'read-more button-orange'}
            />
            <TagList
                tags={props.tags}
            />
        </div>
    )
};

export default ProjectText;
